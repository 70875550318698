<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      sidebar-class="sidebar-lg"
      aria-label="Sidebar with custom footer"
      bg-variant="white"
      :visible="isEventHandlerSidebarActive"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">{{ eventLocal.extendedProps.type }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="px-1 mt-2">
          <b-row>
            <b-col md="9 mx-auto">
              <b-media class="text-center">
                <div class="mb-1">
                  <b-avatar
                    :src="require('@/assets/images/avatars/12-small.png')"
                    size="64"
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.right="
                      eventLocal.extendedProps.scheduleType != 'AE'
                        ? 'Abrir histórico'
                        : ''
                    "
                    :to="
                      $route.params.professor == undefined &&
                      eventLocal.extendedProps.scheduleType != 'AE'
                        ? {
                            name: 'forca-calendar',
                            params: {
                              professor: eventLocal.extendedProps.professor.id,
                            },
                          }
                        : { name: null }
                    "
                  />
                </div>
                <h6 v-if="eventLocal.extendedProps.scheduleType != 'AE'">
                  {{ eventLocal.extendedProps.professor.nome }}
                  {{ eventLocal.extendedProps.professor.sobrenome }}
                </h6>
                <br />
              </b-media>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5 class="d-inline">
                Disciplina:
              </h5>
              {{ eventLocal.extendedProps.nameMateria }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <h5 class="d-inline">
                Data:
              </h5>
              {{ eventLocal.start | moment("llll") | ucFirst }}h
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <h5 class="d-inline">
                Local:
              </h5>
              {{ eventLocal.extendedProps.local }}<br />
              <small class="font-italic"
                >{{ eventLocal.extendedProps.address.endereco }}
                <b-link
                  :href="
                    `https://www.google.com/maps/dir/${eventLocal.extendedProps.professor.lat},${eventLocal.extendedProps.professor.lng}/${eventLocal.extendedProps.address.lat},${eventLocal.extendedProps.address.lng}`
                  "
                  target="_blank"
                  >[Como chegar]</b-link
                ></small
              >
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <h5 class="d-inline">
                Duração:
              </h5>
              {{ eventLocal.extendedProps.duracao }}h
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <h5 class="d-inline">
                Status:
              </h5>
              {{ eventLocal.extendedProps.statusText }}
            </b-col>
          </b-row>
        </div>
      </template>
      <template #footer>
        <sidebar-footer
          :event-local="eventLocal"
          @hide="() => $emit('update:is-event-handler-sidebar-active', false)"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BAvatar,
  BMedia,
  BLink,
  VBTooltip,
} from "bootstrap-vue";

import { toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import SidebarFooter from "./SidebarFooter.vue";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BAvatar,
    BMedia,
    BLink,
    SidebarFooter,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },

  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const {
      eventLocal,

      guestsOptions,
    } = useCalendarEventHandler(toRefs(props));

    return {
      // Add New Event
      eventLocal,
      guestsOptions,
    };
  },
};
</script>
