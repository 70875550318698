<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
      />
    </div>
    <b-row class="mt-50">
      <b-col md="12" class="mx-auto">
        <time-line-calendar
          @refetch-events="refetchEvent"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import { BRow, BCol } from "bootstrap-vue";

import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import TimeLineCalendar from "./timeline-calendar/TimeLineCalendar.vue";
import useCalendar from "./useCalendar";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    TimeLineCalendar,
    BRow,
    BCol,
  },

  watch: {
    "$store.state.calendar.sideBarInfo"(val) {
      this.isEventHandlerSidebarActive = val.isEventHandlerSidebarActive;
      if (val.isEventHandlerSidebarActive) this.event = val.event;
    },
    $route(val) {
      if (val.params.professor != undefined)
        this.$store.commit("calendar/SET_PROFESSOR", val.params.professor);
      else this.$store.commit("calendar/SET_PROFESSOR", null);

      this.refetchEvent();
    },
  },

  methods: {
    refetchEvent() {
      this.refetchEvents();
    },
  },

  created() {
    if (this.$route.params.professor != undefined)
      this.$store.commit("calendar/SET_PROFESSOR", this.$route.params.professor);
    else this.$store.commit("calendar/SET_PROFESSOR", null);
  },

  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar();

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };
  },
};
</script>

<style>
.fc-daygrid.fc-dayGridMonth-view {
  overflow-x: auto !important;
}

.fc-daygrid.fc-dayGridMonth-view > table {
  min-width: 100%;
  width: 1050px !important;
}

.fc-time-grid .fc-slats {
  z-index: 4;
  pointer-events: none;
}

.fc-scroller.fc-time-grid-container {
  overflow: initial !important;
}

.fc thead th.fc-col-header-cell {
  width: 150px !important;
}

.fc .fc-daygrid-day {
  width: 150px !important;
}

.fc-event-title-container {
  font-weight: normal !important;
}

.fc-event-time {
  font-weight: bold;
  min-width: 35px !important;
}

.fc .fc-list-table td {
  padding: 8px 10px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
