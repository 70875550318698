<template>
  <div class="p-2 border-top">
    <b-row class="mb-1 text-center">
      <b-col>
        <small class="font-weight-bold">{{
          eventLocal.extendedProps.descricao
        }}</small>
      </b-col>
    </b-row>
    <div
      class="w-100 d-flex flex-row justify-content-center"
    >
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        @click="$emit('hide')"
        variant="outline-secondary"
        class="float-right"
      >
        Fechar
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },

  directives: {
    Ripple,
  },

  props: {
    eventLocal: {
      type: Object,
      require: true,
    },
  },
};
</script>
