<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <b-button
        v-if="$route.params && $route.params.professor != undefined"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        variant="outline-primary"
        block
        :to="{
          name: 'forca-calendar',
        }"
      >
        Voltar
      </b-button>
      <div class="mt-3">
        <b-row>
          <b-card
            bg-variant="success"
            text-variant="white"
            class="w-100 mx-1 cursor-pointer"
            @click="changeView('Aulas Realizadas')"
          >
            <b-card-title class="text-white mb-0">
              <div class="mb-50">Aulas Realizadas</div>
              <h4 class="text-white">
                {{ aulasRealizadas.length.toString().padStart(2, "0") }} ({{
                  timeIntToString(aulasRealizadasTime)
                }}h)
              </h4>
            </b-card-title>
          </b-card>
        </b-row>
        <b-row>
          <b-card
            bg-variant="warning"
            text-variant="white"
            class="w-100 mx-1 cursor-pointer"
            @click="changeView('Aulas Previstas')"
          >
            <b-card-title class="text-white mb-0">
              <div class="mb-50">Aulas Previstas</div>
              <h4 class="text-white">
                {{ aulasPrevistas.length.toString().padStart(2, "0") }} ({{
                  timeIntToString(aulasPrevistasTime)
                }}h)
              </h4>
            </b-card-title>
          </b-card>
        </b-row>
        <b-row>
          <b-card
            bg-variant="secondary"
            text-variant="white"
            class="w-100 mx-1 cursor-pointer"
            @click="changeView('Aulas Canceladas')"
          >
            <b-card-title class="text-white mb-0">
              <div class="mb-50">Aulas Canceladas</div>
              <h4 class="text-white">
                {{ aulasCanceladas.length.toString().padStart(2, "0") }} ({{
                  timeIntToString(aulasCanceladasTime)
                }}h)
              </h4>
            </b-card-title>
          </b-card>
        </b-row>
        <b-row>
          <b-card
            bg-variant="danger"
            text-variant="white"
            class="w-100 mx-1 cursor-pointer"
            @click="changeView('Faltas')"
          >
            <b-card-title class="text-white mb-0">
              <div class="mb-50">Faltas</div>
              <h4 class="text-white">
                {{ aulasFaltadas.length.toString().padStart(2, "0") }} ({{
                  timeIntToString(aulasFaltadasTime)
                }}h)
              </h4>
            </b-card-title>
          </b-card>
        </b-row>
        <b-form-checkbox v-model="checkAll" class="mb-1 custom-control-info">
          Todos
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img
      v-if="false"
      :src="require('@/assets/images/pages/calendar-illustration.png')"
    />
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BRow,
  BCard,
  BCardTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useCalendarSidebar from "./useCalendarSidebar";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCard,
    BCardTitle,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },

  computed: {
    aulasRealizadasTime() {
      let vm = this;
      let tempo = 0;
      this.aulasRealizadas.map((aula) => {
        tempo += vm.timeStringToInt(aula.extendedProps.duracao);
      });

      return tempo;
    },

    aulasPrevistasTime() {
      let vm = this;
      let tempo = 0;
      this.aulasPrevistas.map((aula) => {
        tempo += vm.timeStringToInt(aula.extendedProps.duracao);
      });

      return tempo;
    },

    aulasCanceladasTime() {
      let vm = this;
      let tempo = 0;
      this.aulasCanceladas.map((aula) => {
        tempo += vm.timeStringToInt(aula.extendedProps.duracao);
      });

      return tempo;
    },

    aulasFaltadasTime() {
      let vm = this;
      let tempo = 0;
      this.aulasFaltadas.map((aula) => {
        tempo += vm.timeStringToInt(aula.extendedProps.duracao);
      });

      return tempo;
    },

    aulasRealizadas() {
      let vm = this;
      return this.$store.state.calendar.events
        .filter((event) => event.extendedProps.calendar == "Aulas Realizadas")
        .map((aula) => {
          aula.extendedProps.durationValue = vm.timeStringToInt(
            aula.extendedProps.duracao
          );
          return aula;
        });
    },

    aulasPrevistas() {
      let vm = this;
      return this.$store.state.calendar.events
        .filter((event) => event.extendedProps.calendar == "Aulas Previstas")
        .map((aula) => {
          aula.extendedProps.durationValue = vm.timeStringToInt(
            aula.extendedProps.duracao
          );
          return aula;
        });
    },
    aulasCanceladas() {
      let vm = this;
      return this.$store.state.calendar.events
        .filter((event) => event.extendedProps.calendar == "Aulas Canceladas")
        .map((aula) => {
          aula.extendedProps.durationValue = vm.timeStringToInt(
            aula.extendedProps.duracao
          );
          return aula;
        });
    },
    aulasFaltadas() {
      let vm = this;
      return this.$store.state.calendar.events
        .filter((event) => event.extendedProps.calendar == "Faltas")
        .map((aula) => {
          aula.extendedProps.durationValue = vm.timeStringToInt(
            aula.extendedProps.duracao
          );
          return aula;
        });
    },
  },

  methods: {
    changeView(view) {
      this.selectedCalendars = [view];
    },

    timeStringToInt(horas) {
      horas = horas.split(":");
      const [H, M] = horas;

      const _segundos = H * 3600 + M * 60;

      return parseInt(_segundos);
    },

    timeIntToString(segundos) {
      const _horas = [
        Math.floor(segundos / 3600)
          .toString()
          .padStart(2, "0"),
        Math.floor((segundos % 3600) / 60)
          .toString()
          .padStart(2, "0"),
      ];

      return _horas.join(":");
    },
  },

  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar();

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    };
  },
};
</script>

<style></style>
