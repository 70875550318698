import axios from "@axios";

export default {
  namespaced: true,
  state: {
    events: [],
    professor: null,
    sideBarInfo: {
      isEventHandlerSidebarActive: false,
      event: {},
    },
    calendarOptions: [
      {
        color: "success",
        label: "Aulas Realizadas",
      },
      {
        color: "warning",
        label: "Aulas Previstas",
      },
      {
        color: "secondary",
        label: "Aulas Canceladas",
      },
      {
        color: "danger",
        label: "Faltas",
      },
    ],
    selectedCalendars: [
      "Aulas Realizadas",
      "Aulas Previstas",
      "Aulas Canceladas",
      "Faltas",
    ],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
    SET_PROFESSOR(state, val) {
      state.professor = val;
    },
    SET_EVENTS(state, val) {
      state.events = val;
    },
    SET_SIDEBARINFO(state, val) {
      state.sideBarInfo = val;
    },
    UPDATE_EVENT(state, event) {
      const eventsIndex = state.events.findIndex((e) => e.id == event.id);
      if (eventsIndex !== -1) Object.assign(state.events[eventsIndex], event);
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, dates }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/calendar", {
            calendars: calendars,
            dates: dates,
            professor: ctx.state.professor,
          })
          .then((response) => {
            this.commit("calendar/SET_EVENTS", response.data);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    missingEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .put("/calendar/event/missing", event)
          .then((response) => {
            this.commit("calendar/UPDATE_EVENT", response.data);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateEvent(ctx, { event, update }) {
      return new Promise((resolve, reject) => {
        if (update) {
          axios
            .put("/calendar/relatorio/store", event)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        } else {
          axios
            .post("/calendar/relatorio/store", event)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        }
      });
    },

    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/calendar/events", { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvents(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
