<template>
  <div v-if="rows">
    <b-card>
      <b-card-header>
        <b-card-title class="ml-25">
          Timeline de Atividades
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <vue-paginate-scroll
          v-if="rows"
          :src="items"
          :per-scroll="perPage"
          :observerOptions="{ threshold: 0.75 }"
        >
          <template slot-scope="{ data }">
            <app-timeline>
              <app-timeline-item
                v-for="(event, key) in data"
                v-bind:key="key"
                :variant="event.extendedProps.classColor"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 cursor-pointer"
                  @click="openSideBar(event)"
                >
                  <div class="container-fluid bg-dark"></div>
                  <h6>{{ event.materia }}</h6>
                  <b-badge
                    pill
                    :variant="`light-${event.extendedProps.classColor}`"
                  >
                    {{ event.start | moment("DD/MM/YYYY - H:mm") }}
                  </b-badge>
                </div>
                <p
                  style="margin-top: -5px"
                  class="cursor-pointer"
                  @click="openSideBar(event)"
                >
                  <small>Duração: {{ event.extendedProps.duracao }}h</small>
                </p>
                <b-media class="cursor-pointer" @click="openSideBar(event)">
                  <template #aside>
                    <b-avatar
                      :src="require('@/assets/images/avatars/12-small.png')"
                    />
                  </template>
                  <h6>
                    {{ event.extendedProps.professor.nome }}
                    {{ event.extendedProps.professor.sobrenome }}
                  </h6>
                  <p>
                    <small>{{ event.extendedProps.local }}</small>
                  </p>
                </b-media>
                <div v-if="event.extendedProps.scheduleType != 'AE'">
                  <b-collapse
                    v-if="event.extendedProps.report"
                    v-model="itemCollapsed[event.id]"
                  >
                    <!-- {{ event.extendedProps.reportData.json }} -->
                    <div class="mt-2">
                      <h5>RELATÓRIO</h5>
                    </div>
                    <b-list-group flush class="mt-1">
                      <b-list-group-item
                        href="#"
                        class="d-flex justify-content-between align-items-center bg-transparent"
                        v-for="(report, reportKey) in filter(
                          event.extendedProps.reportData.json,
                          (report) =>
                            report.mensagem != null && report.mensagem != ''
                        )"
                        v-bind:key="reportKey"
                      >
                        <div>
                          <span class="font-weight-bold">{{
                            report.titulo
                          }}</span>
                          <br
                            v-if="
                              report.mensagem != null &&
                                report.mensagem.length > 90
                            "
                          />
                          <span class="font-italic">
                            {{ report.mensagem }}</span
                          >
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </div>
              </app-timeline-item>

              <!-- DESIGNING UI -->
              <app-timeline-item variant="info" v-if="false">
                <div
                  class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50"
                >
                  <h6>Designing UI</h6>
                  <b-badge pill variant="light-primary">
                    Design
                  </b-badge>
                </div>
                <p>
                  Our main goal is to design a new mobile application for our
                  client. The customer wants a clean &amp; flat design.
                </p>
                <div>
                  <span class="text-muted">
                    Participants
                  </span>
                  <b-avatar-group size="32px" class="mt-50">
                    <b-avatar
                      v-b-tooltip.hover
                      class="pull-up"
                      title="Jenny Looper"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-5.jpg')
                      "
                    />
                    <b-avatar
                      v-b-tooltip.hover
                      class="pull-up"
                      title="Jennifer Lopez"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-6.jpg')
                      "
                    />
                    <b-avatar
                      v-b-tooltip.hover
                      class="pull-up"
                      title="Arya Stark"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-7.jpg')
                      "
                    />
                  </b-avatar-group>
                </div>
              </app-timeline-item>
            </app-timeline>
          </template>
        </vue-paginate-scroll>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BAvatar,
  BMedia,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "TimeLineCalendar",
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BAvatar,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
  },

  data() {
    return {
      currentPage: 1,
      perPage: 15,
      itemCollapsed: {},
    };
  },

  watch: {
    rows() {
      this.itemCollapsed = {};
    },
  },

  computed: {
    events() {
      return this.$store.state.calendar.events;
    },

    items() {
      let events = this.events;

      return events.sort(this.sortDate);
    },

    rows() {
      return this.events.length;
    },
  },

  destroyed() {
    this.itemCollapsed = {};
  },

  methods: {
    reportState(event) {
      const eventState =
        this.itemCollapsed[event.id] != undefined
          ? !this.itemCollapsed[event.id]
          : true;

      this.$set(this.itemCollapsed, event.id, eventState);
      return eventState;
    },
    sortDate(a, b) {
      let created_atA = moment(a.start);
      let created_atB = moment(b.end);

      if (created_atA.unix() < created_atB.unix()) return -1;
      if (created_atA.unix() > created_atB.unix()) return 1;
      return 0;
    },

    openSideBar(event) {
      if (event.id === undefined) return;

      this.$store.commit("calendar/SET_SIDEBARINFO", {
        isEventHandlerSidebarActive: true,
        event: event,
      });
    },

    filter(obj, predicate) {
      return Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});
    },
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
};
</script>
