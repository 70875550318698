var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rows)?_c('div',[_c('b-card',[_c('b-card-header',[_c('b-card-title',{staticClass:"ml-25"},[_vm._v(" Timeline de Atividades ")])],1),_c('b-card-body',[(_vm.rows)?_c('vue-paginate-scroll',{attrs:{"src":_vm.items,"per-scroll":_vm.perPage,"observerOptions":{ threshold: 0.75 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('app-timeline',[_vm._l((data),function(event,key){return _c('app-timeline-item',{key:key,attrs:{"variant":event.extendedProps.classColor}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 cursor-pointer",on:{"click":function($event){return _vm.openSideBar(event)}}},[_c('div',{staticClass:"container-fluid bg-dark"}),_c('h6',[_vm._v(_vm._s(event.materia))]),_c('b-badge',{attrs:{"pill":"","variant":("light-" + (event.extendedProps.classColor))}},[_vm._v(" "+_vm._s(_vm._f("moment")(event.start,"DD/MM/YYYY - H:mm"))+" ")])],1),_c('p',{staticClass:"cursor-pointer",staticStyle:{"margin-top":"-5px"},on:{"click":function($event){return _vm.openSideBar(event)}}},[_c('small',[_vm._v("Duração: "+_vm._s(event.extendedProps.duracao)+"h")])]),_c('b-media',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.openSideBar(event)}},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":require('@/assets/images/avatars/12-small.png')}})]},proxy:true}],null,true)},[_c('h6',[_vm._v(" "+_vm._s(event.extendedProps.professor.nome)+" "+_vm._s(event.extendedProps.professor.sobrenome)+" ")]),_c('p',[_c('small',[_vm._v(_vm._s(event.extendedProps.local))])])]),(event.extendedProps.scheduleType != 'AE')?_c('div',[(event.extendedProps.report)?_c('b-collapse',{model:{value:(_vm.itemCollapsed[event.id]),callback:function ($$v) {_vm.$set(_vm.itemCollapsed, event.id, $$v)},expression:"itemCollapsed[event.id]"}},[_c('div',{staticClass:"mt-2"},[_c('h5',[_vm._v("RELATÓRIO")])]),_c('b-list-group',{staticClass:"mt-1",attrs:{"flush":""}},_vm._l((_vm.filter(
                        event.extendedProps.reportData.json,
                        function (report) { return report.mensagem != null && report.mensagem != ''; }
                      )),function(report,reportKey){return _c('b-list-group-item',{key:reportKey,staticClass:"d-flex justify-content-between align-items-center bg-transparent",attrs:{"href":"#"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(report.titulo))]),(
                            report.mensagem != null &&
                              report.mensagem.length > 90
                          )?_c('br'):_vm._e(),_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(report.mensagem))])])])}),1)],1):_vm._e()],1):_vm._e()],1)}),(false)?_c('app-timeline-item',{attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50"},[_c('h6',[_vm._v("Designing UI")]),_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" Design ")])],1),_c('p',[_vm._v(" Our main goal is to design a new mobile application for our client. The customer wants a clean & flat design. ")]),_c('div',[_c('span',{staticClass:"text-muted"},[_vm._v(" Participants ")]),_c('b-avatar-group',{staticClass:"mt-50",attrs:{"size":"32px"}},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pull-up",attrs:{"title":"Jenny Looper","src":require('@/assets/images/portrait/small/avatar-s-5.jpg')}}),_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pull-up",attrs:{"title":"Jennifer Lopez","src":require('@/assets/images/portrait/small/avatar-s-6.jpg')}}),_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pull-up",attrs:{"title":"Arya Stark","src":require('@/assets/images/portrait/small/avatar-s-7.jpg')}})],1)],1)]):_vm._e()],2)]}}],null,false,1062517539)}):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }