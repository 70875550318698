var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-footer","sidebar-class":"sidebar-lg","aria-label":"Sidebar with custom footer","bg-variant":"white","visible":_vm.isEventHandlerSidebarActive,"shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-event-handler-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.eventLocal.extendedProps.type))]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('div',{staticClass:"px-1 mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"9 mx-auto"}},[_c('b-media',{staticClass:"text-center"},[_c('div',{staticClass:"mb-1"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                    _vm.eventLocal.extendedProps.scheduleType != 'AE'
                      ? 'Abrir histórico'
                      : ''
                  ),expression:"\n                    eventLocal.extendedProps.scheduleType != 'AE'\n                      ? 'Abrir histórico'\n                      : ''\n                  ",modifiers:{"hover":true,"right":true}}],attrs:{"src":require('@/assets/images/avatars/12-small.png'),"size":"64","to":_vm.$route.params.professor == undefined &&
                    _vm.eventLocal.extendedProps.scheduleType != 'AE'
                      ? {
                          name: 'forca-calendar',
                          params: {
                            professor: _vm.eventLocal.extendedProps.professor.id,
                          },
                        }
                      : { name: null }}})],1),(_vm.eventLocal.extendedProps.scheduleType != 'AE')?_c('h6',[_vm._v(" "+_vm._s(_vm.eventLocal.extendedProps.professor.nome)+" "+_vm._s(_vm.eventLocal.extendedProps.professor.sobrenome)+" ")]):_vm._e(),_c('br')])],1)],1),_c('b-row',[_c('b-col',[_c('h5',{staticClass:"d-inline"},[_vm._v(" Disciplina: ")]),_vm._v(" "+_vm._s(_vm.eventLocal.extendedProps.nameMateria)+" ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h5',{staticClass:"d-inline"},[_vm._v(" Data: ")]),_vm._v(" "+_vm._s(_vm._f("ucFirst")(_vm._f("moment")(_vm.eventLocal.start,"llll")))+"h ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h5',{staticClass:"d-inline"},[_vm._v(" Local: ")]),_vm._v(" "+_vm._s(_vm.eventLocal.extendedProps.local)),_c('br'),_c('small',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.eventLocal.extendedProps.address.endereco)+" "),_c('b-link',{attrs:{"href":("https://www.google.com/maps/dir/" + (_vm.eventLocal.extendedProps.professor.lat) + "," + (_vm.eventLocal.extendedProps.professor.lng) + "/" + (_vm.eventLocal.extendedProps.address.lat) + "," + (_vm.eventLocal.extendedProps.address.lng)),"target":"_blank"}},[_vm._v("[Como chegar]")])],1)])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h5',{staticClass:"d-inline"},[_vm._v(" Duração: ")]),_vm._v(" "+_vm._s(_vm.eventLocal.extendedProps.duracao)+"h ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h5',{staticClass:"d-inline"},[_vm._v(" Status: ")]),_vm._v(" "+_vm._s(_vm.eventLocal.extendedProps.statusText)+" ")])],1)],1)]}},{key:"footer",fn:function(){return [_c('sidebar-footer',{attrs:{"event-local":_vm.eventLocal},on:{"hide":function () { return _vm.$emit('update:is-event-handler-sidebar-active', false); }}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }